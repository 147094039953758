/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                $('.hamburger').on('click', function () {
                    $('body').toggleClass('menu-open');
                    $('.nav-primary').toggleClass('open');
                    $(this).toggleClass('is-active');
                });
                var elems = $('.nav-primary, .hamburger');
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        'single_product': {
            init: function () {

                $("input.qty").attr('type', 'text').TouchSpin({
                    min: 1,
                    max: 10000,
                    stepinterval: 1
                });
            },
            finalize: function () {

            }
        },
        // About us page, note the change from about-us to about_us.
        'sidebar_primary': {
            init: function () {
                $(".sidebar-inner").stick_in_parent({
                    offset_top: 20
                });
            }
        },
        'register': {
            init: function () {

                $('#field_1_5').css('position', 'relative');

                $('<div class="same-billing">' +
                    '<label class="sr-only">Same as Billing</label><input type="checkbox"' +
                    ' value="" checked></div>').prependTo($('#field_1_5'));

                $('.same-billing input').on('click', function () {
                    if(!$(this).is(':checked')) {
                        $('#field_1_5 input').val('');
                        $('#field_1_5 select').prop('selectedIndex',0);
                        $(this).closest('.gfield').addClass('different');
                    }
                });

                var $billingField = 4,
                    $shippingField = $billingField + 1;

                $("#field_1_" + $billingField).on('blur keyup','input', function() {
                    var shipID = $(this).attr("id").slice(-1);
                    $(this).closest('.gfield').next().find("#input_1_" + $shippingField + "_" + shipID).val( this.value );
                });

                $("#field_1_" + $billingField).on('change','select', function() {
                    var shipID = $(this).attr("id").slice(-1);
                    $(this).closest('.gfield').next().find("#input_1_" + $shippingField + "_" + shipID).val( this.value );
                });
            }
        }
    };
    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';
            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');
            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });
            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };
    // Load Events
    $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
